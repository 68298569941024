<template>
  <b-form
    @submit.prevent="$emit('submit', model)"
    @keydown.enter="$emit('submit', model)"
  >
    <b-row>
      <b-col sm="12">
        <b-form-group>
          <label>
            Fecha entre
          </label>
          <b-row no-gutters>
            <b-col cols="6">
              <b-form-datepicker
                v-model="model.date_min"
                class="margin-right-datepicker"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                size="sm"
                reset-button
                :hide-header="true"
                placeholder="Desde"
                :label-help="null"
                :max="model.date_max"
                @input="update('date_min', $event)"
                start-weekday="1"
              />
            </b-col>
            <b-col cols="6">
              <b-form-datepicker
                :value="model.date_max"
                class="margin-left-datepicker"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                size="sm"
                reset-button
                :hide-header="true"
                placeholder="Hasta"
                :label-help="null"
                :min="model.date_min"
                @input="update('date_max', $event)"
                start-weekday="1"
              />
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Nombre"
          label-for="job-name"
        >
          <b-form-input
            id="job-name"
            :value="model.name"
            name="name"
            placeholder="Nombre"
            @input="update('name', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Project Manager"
          label-for="job-project-manager"
        >
          <BaseSelect
            id="job-project-manager"
            :value="model.user"
            :resource="$data.$constants.RESOURCES.RESOURCE_USERS"
            @input="update('user', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Cliente"
          label-for="job-client"
        >
          <BaseSelect
            id="job-client"
            :value="model.client"
            :resource="$data.$constants.RESOURCES.RESOURCE_CLIENTS"
            data-cy="jobClientSelect"
            @input="update('client', $event)"
            :multiple="true"
            :reduce="((item) => {return { id: item.id, name: item.name }})"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Obra"
          label-for="job-project"
        >
          <BaseSelect
            id="job-project"
            :value="model.project"
            :resource="$data.$constants.RESOURCES.RESOURCE_PROJECTS"
            data-cy="jobProjectSelect"
            @input="update('project', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Estado"
          label-for="job-status"
        >
          <StatusSelect
            id="job-status"
            :value="model.status"
            :type="statusType"
            @input="update('status', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="División"
          label-for="job-division"
        >
          <BaseSelect
            id="job-division"
            :value="model.division"
            :resource="$data.$constants.RESOURCES.RESOURCE_DIVISIONS"
            http-method="get"
            @input="update('division', $event)"
            :reduce="((item) => {return { id: item.id, name: item.name }})"
            :multiple="true"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import FormMixin from '@/mixins/formMixin'
import { JOBS_STATUS_TYPE } from '@/api/status-api'
import BaseSelect from '@/components/ui/select/BaseSelect.vue'
import StatusSelect from '@/components/status/select/StatusSelect.vue'

export default {
  name: 'JobListFiltersForm',
  components: { StatusSelect, BaseSelect },
  mixins: [FormMixin],
  data() {
    return {
      statusType: JOBS_STATUS_TYPE,
    }
  },
}
</script>

<style scoped>
.margin-left-datepicker {
  margin-left: 5px;
}
.margin-right-datepicker {
  margin-right: 5px;
}
</style>
